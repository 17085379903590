import '../css/app.scss';
/* import '../css/home.scss'; */

import 'swiper/swiper-bundle.css';

import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.bundle.js';

import { CountUp } from 'countup.js';


import $ from 'jquery';

window.jQuery = $;

global.$ = global.jQuery = $;

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';

import firebaseConfig from "./firebaseconfig";  

// import Swiper from 'swiper';
import Swiper from 'swiper/bundle';
import 'slideout/dist/slideout.js';
import Slideout from 'slideout'

require('jquery');

/* window.jQuery = $; */

// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { offset } from '@popperjs/core';

(function() {
    let header = document.getElementById('header-mobile');

    const mediaQuery = window.matchMedia('(min-width: 768px)');
    
    if (mediaQuery.matches) {
        header = document.getElementById('header-desk');
    }

    if (header.classList.contains('header-home')) {

        window.addEventListener("scroll", headerActiveBgColor);
    
        function headerActiveBgColor() {
            let scrollY = window.scrollY;
    
            if (scrollY > 100) {
                header.classList.add("active");
            } else {
                header.classList.remove("active");
            }
        } 
    }
})();


(function() {
    const iconoMenuHamburguesa = document.getElementById('icono-menu-hamburguesa');
    const slideMenuMobile = document.getElementById('slide-menu-mobile');
    const overlaySlideMenuMobile = document.getElementById('overlay-slide-menu-mobile');
    const closeSlideMenuMobile = document.getElementById('close-slide-menu-mobile');

    iconoMenuHamburguesa.addEventListener("click", showSlideMenuMobile);
    overlaySlideMenuMobile.addEventListener("click", hideSlideMenuMobile);
    closeSlideMenuMobile.addEventListener("click", hideSlideMenuMobile);

    function showSlideMenuMobile() {
        overlaySlideMenuMobile.classList.add("show-overlay-slide-menu-mobile");
        slideMenuMobile.classList.add("show-slide-menu-mobile");
    }
    function hideSlideMenuMobile() {
        overlaySlideMenuMobile.classList.remove("show-overlay-slide-menu-mobile");
        slideMenuMobile.classList.remove("show-slide-menu-mobile");
    }
})();


$(function () {

    console.log('app js interia');

    const swiperServiciosSoluciones = new Swiper('.servicios-soluciones .swiper-container', {
        autoplay: {
            delay: 4000,
            disableOnInteraction: true
        },
        navigation: {
            nextEl: '.servicios-soluciones .swiper-button-next',
            prevEl: '.servicios-soluciones .swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 0
            },
            576: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 20
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 50
            }
        }
    });


    const swiperServiciosSolucionesMore = new Swiper('.servicios-soluciones-more .swiper-container', {
        autoplay: {
            delay: 4000,
            disableOnInteraction: true
        },
        navigation: {
            nextEl: '.servicios-soluciones-more .swiper-button-next',
            prevEl: '.servicios-soluciones-more .swiper-button-prev',
        },
    });

    

    const swiperClientes = new Swiper('.clientes .swiper-container', {
        autoplay: {
            delay: 4000,
            disableOnInteraction: true
        },
        navigation: {
            nextEl: '.clientes .swiper-button-next',
            prevEl: '.clientes .swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 0
            },
            576: {
                slidesPerView: 2,
                spaceBetween: 0
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 0
            },
            992: {
                slidesPerView: 4,
                spaceBetween: 0
            },
            1200: {
                slidesPerView: 6,
                spaceBetween: 0
            }
        }
    });


    const  testimoniosSwiper = new Swiper('.testimonios .swiper-container', {
        autoplay: {
            delay: 4000,
            disableOnInteraction: true
        },
        navigation: {
            nextEl: '.testimonios .swiper-button-next',
            prevEl: '.testimonios .swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 10
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 10
            },
        }
    });

    const  reglasGestionCarteraComercialSwiper = new Swiper('.reglas-gestion-cartera-comercial .swiper-container', {
        autoplay: {
            delay: 4000,
            disableOnInteraction: true
        },
        navigation: {
            nextEl: '.reglas-gestion-cartera-comercial .swiper-button-next',
            prevEl: '.reglas-gestion-cartera-comercial .swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 0
            },
        }
    });


    const  seccionInfoSwiper = new Swiper('.seccion-info .swiper-container', {
        autoplay: {
            delay: 10000,
            disableOnInteraction: true
        },
        navigation: {
            nextEl: '.seccion-info .swiper-button-next',
            prevEl: '.seccion-info .swiper-button-prev',
        }
    });


    const swiperBoletinInformativo = new Swiper('.boletin-informativo .swiper-container', {
        autoplay: {
            delay: 4000,
            disableOnInteraction: true
        },
        navigation: {
            nextEl: '.boletin-informativo .swiper-button-next',
            prevEl: '.boletin-informativo .swiper-button-prev',
        }
    });


    /* $("#cart").on("click", function(e) {
        e.preventDefault();
        $(".shopping-cart").fadeToggle( "fast");
    });
    $('#form').submit(function (e) {
        e.preventDefault();
    });
    $('#anterior').change(function () {
        if($(this).val() != 0){
            window.location = $(this).val();
        }
    })
    $('#cerrar_sesion').click(function (e) {
        console.log("cerrar sesion");
        e.preventDefault();
        firebase.auth().signOut();
        window.location = '/logout';
    });
    $(".usuario-tool").click(function(){
        if($(this).hasClass("menu-open")){
            $(this).removeClass("menu-open");
        } else {
            $(this).addClass("menu-open");
        }
    });
    $(".menu-mobile").click(function(){
        if($(this).hasClass("menu-open-dos")){
            $(this).removeClass("menu-open-dos");
            $(".menue").removeClass("menu-open-dos");
        } else {
            $(this).addClass("menu-open-dos");
            $(".menue").addClass("menu-open-dos");
        }
    }); */

    $.ajax({
        url: "/ajax-cart",
        type: 'get',
        dataType: "html",
        success: function( html ) {
            $('#cart-items').html(html);
            //fbq('track', 'AddToCart');
        }
    });

    sizeWindow();

    lineaTiempo();
});

// formulario de contactanos
$('#formcontactanos').submit(function(e) {
    e.preventDefault();
    $.ajax({
        type: 'POST',
        url: $(this).attr('action'),
        data:$(this).serialize(),
        dataType: "json",
    })
        .done(function (msg) {
            alert(msg.mensaje);
            document.location.reload();                        
        });
    return false;
});

// formulario de usuarios Boletín informativo
$('#usersBoletin').submit(function(e) {
    e.preventDefault();
    $.ajax({
        type: 'POST',
        url: $(this).attr('action'),
        data:$(this).serialize(),
        dataType: "json",
    })
        .done(function (msg) {
            alert(msg.mensaje);
            document.location.reload();                        
        });
    return false;
});

// formulario de comentarios
$('#formcomentario').submit(function(e) {
    e.preventDefault();
    $.ajax({
        type: 'POST',
        url: $(this).attr('action'),
        data:$(this).serialize(),
        dataType: "json",
    })
        .done(function (msg) {
            alert(msg.mensaje);
            document.location.reload();                        
        });
    return false;
});





(function () {

    // if( $('.capacidad-numeros-empresa').length>0 ) {

        let alturaHeader = document.getElementById('header-mobile').offsetHeight;

        // ANIMACION NUMEROS DEL HOME
        // const cantidadScrollSeccionCapacidadNumerosEmpresa = document.getElementById('seccion-capacidad-numeros-empresa').offsetTop;
        // const offset = 90;
        // var exp = document.getElementById('experiencia').value;
        // var oblig = document.getElementById('obligaciones').value;
        // var client = document.getElementById('clientes').value;
        // const numeroAniosExperiencia = new CountUp('numero-anios-experiencia', exp );
        // const numeroObligacionesGestionadas = new CountUp('numero-obligaciones-gestionadas', oblig);
        // const numeroClientes = new CountUp('numero-clientes', client);
    
        const mediaQuery = window.matchMedia('(min-width: 768px)');
            if (mediaQuery.matches) {
            alturaHeader = document.getElementById('header-desk').offsetHeight;
        }
    
        window.addEventListener("scroll", scrollPageHeader);
        
    // }

    function scrollPageHeader() {
        let scrollY = window.scrollY;

        // if ((scrollY + alturaHeader) + offset >= cantidadScrollSeccionCapacidadNumerosEmpresa) {
        //     if (!numeroAniosExperiencia.error) {
        //       numeroAniosExperiencia.start();
        //     } else {
        //       console.error(numeroAniosExperiencia.error);
        //     }
        //     if (!numeroObligacionesGestionadas.error) {
        //       numeroObligacionesGestionadas.start();
        //     } else {
        //       console.error(numeroObligacionesGestionadas.error);
        //     }
        //     if (!numeroClientes.error) {
        //       numeroClientes.start();
        //     } else {
        //       console.error(numeroClientes.error);
        //     }
        // } 
    }
})();

function lineaTiempo(){

    var time = 1000;

    $('.linea-tiempo .item').each(function(){
        var top = $(this).data('top');
        var left = $(this).data('left');
        var item = $(this);
        $(this).css({'top': top+'%', left: left+'%'});

        setTimeout(function(){
            $(item).addClass('view');
            console.log('time', time);
        }, time);

        time += 200;
    });

}

$(window).on('resize', function(){
    console.log('cambio de tamaño');
    sizeWindow();
});

function sizeWindow(){
    var wWindow = $(window).outerWidth();
    console.log('tamaño', wWindow);
    if(wWindow <= 575){
        //phone
        slideMenu();
    }else if(wWindow <= 767){
        //landscape phone
    }else if(wWindow <= 991){
        //tablets
    }else if(wWindow >= 991){
        //desktop
    }
}

function slideMenu(){
    var slideout = new Slideout({
        'panel': document.getElementById('panel'),
        'menu': document.getElementById('menu'),
        'padding': 256,
        'tolerance': 70
    });

    // Toggle button
    document.querySelector('.toggle-button').addEventListener('click', function() {
        slideout.toggle();
    });
}

function setLatLng(latLng) {
    $('#lat').val(latLng.lat());
    $('#lng').val(latLng.lng());
}

/**
 * Handles the sign up button press.
 */
function handleSignUp() {
    showLoader();
    $('#errores').hide();
    var nombre = document.getElementById('nombre').value;
    var apellido = document.getElementById('apellido').value;
    var email = document.getElementById('email').value;
    var documento = document.getElementById('documento').value;
    var telefono = document.getElementById('telefono').value;
    var password = document.getElementById('password').value;
    var password_second = document.getElementById('password_second').value;
    if (nombre.length < 2 && nombre.trim()==null || nombre.trim()=="" || nombre===" ") {
        setError('Ingresa tu nombre');
        return;
    }
    if (apellido.length < 3 && apellido.trim()==null || apellido.trim()=="" || apellido===" ") {
        setError('Ingresa tu apellido');
        return;
    }
    if (documento.length < 3 && documento.trim()==null || documento.trim()=="" || documento===" ") {
        setError('Ingresa tu documento');
        return;
    }
    if (telefono.length < 7 && telefono.trim()==null || telefono.trim()=="" || telefono===" ") {
        setError('Ingresa tu telefono');
        return;
    }
    if (email.length < 4 && email.trim()==null || email.trim()=="" || email===" ") {
        setError('Ingresa tu email');
        return;
    }
    if (password.length < 4 && password.trim()==null || password.trim()=="" || password===" ") {
        setError('Ingresa tu contraseña');
        return;
    }
    if (password != password_second) {
        setError('Las contraseñas deben coincidir');
        return;
    }
    firebase.auth().createUserWithEmailAndPassword(email, password).catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode == 'auth/weak-password') {
            setError('La contraseña debe tener al menos 6 caracteres');
        } else {
            setError(errorMessage);
        }
        console.log(error);
    });
}
/**
 * Handles the sign in button press.
 */
function toggleSignIn() {
    showLoader();
    $('#errores').hide();
    if (firebase.auth().currentUser) {
    } else {
        var email = document.getElementById('email').value;
        var password = document.getElementById('password').value;
        if (email.length < 4) {
            setError('Ingresa tu email');
            return;
        }
        if (password.length < 4) {
            setError('Ingresa tu contraseña');
            return;
        }
        firebase.auth().signInWithEmailAndPassword(email, password).catch(function(error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            if (errorCode === 'auth/wrong-password') {
                setError('Contraseña Inválida');
            } else {
                setError(errorMessage);
            }
            console.log(error);
            document.getElementById('quickstart-sign-in').disabled = false;
        });
    }
    document.getElementById('quickstart-sign-in').disabled = true;
}

function sendPasswordReset() {
    showLoader();
    $('#errores').hide();
    var email = document.getElementById('email').value;
    // [START sendpasswordemail]
    firebase.auth().sendPasswordResetEmail(email).then(function() {
        $('#success').show();
    }).catch(function(error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode == 'auth/invalid-email') {
            setError('Email inválido');
        } else if (errorCode == 'auth/user-not-found') {
            setError('Usuario no encontrado');
        }
        console.log(error);
        // [END_EXCLUDE]
    });
    // [END sendpasswordemail];
}

function setError(mensaje) {
    $('.loader-wrapper').hide();
    $('#errores').show().html(mensaje);
    window.scrollTo(0, 0);
}

function showLoader() {
    $('.loader-wrapper').show();
}


function initApp() {
    // Listening for auth state changes.
    // [START authstatelistener]
    firebase.auth().onAuthStateChanged(function(user) {
        // [START_EXCLUDE silent]
        //document.getElementById('quickstart-verify-email').disabled = true;
        // [END_EXCLUDE]
        if (user) {
            // User is signed in.
            var displayName = user.displayName;
            var email = user.email;
            var emailVerified = user.emailVerified;
            var photoURL = user.photoURL;
            var isAnonymous = user.isAnonymous;
            var uid = user.uid;
            var providerData = user.providerData;
            var user_up = {};
            if($('#nombre').length > 0){
                var nombre = document.getElementById('nombre').value;
                user_up.nombre = nombre;
            }
            if($('#apellido').length > 0){
                var apellido = document.getElementById('apellido').value;
                user_up.apellido = apellido;
            }
            if($('#telefono').length > 0){
                var telefono = document.getElementById('telefono').value;
                user_up.telefono = telefono;
            }
            if($('#documento').length > 0){
                var documento = document.getElementById('documento').value;
                user_up.documento = documento;
            }
            user_up.email = email;
            user_up.uid = uid;
            user_up.providerData = providerData;
            //user_up.telefono = user.telefono;
            console.log(user);
            console.log("user_up",user_up);

            $.ajax({
                type: "POST",
                url: "/api/auth/register",
                // The key needs to match your method's input parameter (case-sensitive).
                data: JSON.stringify(user_up),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function(data){
                    console.log(data);
                    window.location = data.url;
                },
                failure: function(errMsg) {
                    alert(errMsg);
                }
            });


        } else {
        }
    });

    if($('#quickstart-sign-in').length > 0)
        document.getElementById('quickstart-sign-in').addEventListener('click', toggleSignIn, false);
    if($('#quickstart-sign-up').length > 0)
        document.getElementById('quickstart-sign-up').addEventListener('click', handleSignUp, false);
    //document.getElementById('quickstart-verify-email').addEventListener('click', sendEmailVerification, false);
    if($('#quickstart-password-reset').length > 0){
        document.getElementById('quickstart-password-reset').addEventListener('click', sendPasswordReset, false);
    }
}

window.onload = function() {
    console.log('ini fire');
    if(window.location.href.includes('crear-cuenta') || window.location.href.includes('ingresar') || window.location.href.includes('olvide')) {
        initApp();
    }
};
