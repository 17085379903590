var firebaseconfig = {
    apiKey: "AIzaSyBViKpWBSEXTXBZ4k0A6p1sdph8uBCvy_0",
    authDomain: "biospifar-64d64.firebaseapp.com",
    databaseURL: "https://biospifar-64d64-default-rtdb.firebaseio.com",
    projectId: "biospifar-64d64",
    storageBucket: "biospifar-64d64.appspot.com",
    messagingSenderId: "295293856399",
    appId: "1:295293856399:web:ff5a9dfca0d87be4b29efa",
    measurementId: "G-K88KKTH0Y9"
};

export default firebaseconfig;
